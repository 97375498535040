import React from 'react'
import { Link } from 'gatsby'
import GlitchText from 'react-glitch-effect/core/GlitchText'

import logo from '../images/logo.png'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <div className='flex flex-column not-found justify-center items-center'>
    <SEO title="404: Not found" />
    <div className="container text-center">
      <img alt="monkey coding" src={logo} className="avatar self-center" />
      <div className="pt1 pb1">
        <GlitchText component='h1'>
          NOT FOUND
        </GlitchText>
        <p className="pt1 pb1">You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
      <Link to="/">Go back home</Link>
    </div>
  </div>
)

export default NotFoundPage
